import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, Signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { GetAssetResponse } from '@assethub/shared/models';
import { LocalizedDatePipe } from '@assethub/shared/pipes';
import {
  AssetConnectionState,
  AssetConnectionStateService,
  AssetConnnectionDetails,
} from '@assethub/shared/services';
import { TranslateModule } from '@ngx-translate/core';
import { switchMap } from 'rxjs';

@Component({
  selector: 'app-asset-connection-state',
  standalone: true,
  imports: [CommonModule, TranslateModule, LocalizedDatePipe],
  templateUrl: './asset-connection-state.component.html',
  styleUrl: './asset-connection-state.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetConnectionStateComponent {
  private readonly assetConnectionStateService = inject(AssetConnectionStateService);

  readonly asset = input.required<GetAssetResponse>();

  private readonly connectionDetails: Signal<AssetConnnectionDetails> = toSignal(
    toObservable(this.asset).pipe(
      switchMap(asset => this.assetConnectionStateService.fetchConnectionState(asset)),
    ),
    { initialValue: { state: AssetConnectionState.VOID } },
  );

  readonly state = computed(() => this.connectionDetails().state);
  readonly stateDate = computed(() => this.connectionDetails().stateDate);
  readonly isOnline = computed(() => this.state() === AssetConnectionState.ONLINE);
  readonly isOffline = computed(() => this.state() === AssetConnectionState.OFFLINE);
}

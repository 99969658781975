import { NgIf } from '@angular/common';
import { Component, Inject, OnInit, ViewChild, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import {
  ConsentService,
  LicensingService,
  NavigationTrackingService,
  TableConfigService,
  TreeService,
  UserConfigurationService,
} from '@assethub/shared/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BlockUI, BlockUIModule } from 'primeng/blockui';
import { DialogService } from 'primeng/dynamicdialog';
import { firstValueFrom, from } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppInitializerService } from './app-initializer.service';
import { ReleaseNoteDialogComponent, ReleaseNotesService } from './release-notes';
import { DrawerMenuItem, ENVIRONMENT } from './shared/models';
import { BlockUiService } from './shared/services';
import { AppSharedModule } from './shared/shared.module';
import { FEATURE_TOGGLE, FeatureToggleEvalFn } from './shared/utils';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [AppSharedModule, NgIf, BlockUIModule, RouterOutlet, TranslateModule],
})
export class AppComponent implements OnInit {
  @ViewChild('blockUiComponent') blockUiComponent?: BlockUI;

  title = inject(ENVIRONMENT).title;
  startApp = false;
  ready = 0;
  total = 0;
  progress: any[] = [];
  busy = true;
  uiBlocked = false;
  navItems: DrawerMenuItem[] = [];
  showNavigation = true;

  private readonly fadeTime = 750;

  constructor(
    private init: AppInitializerService,
    private userConfigurationService: UserConfigurationService,
    private licensingService: LicensingService,
    private consentService: ConsentService,
    private navigationTrackingService: NavigationTrackingService,
    private treeService: TreeService,
    private releaseNoteService: ReleaseNotesService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private router: Router,
    private blockUiService: BlockUiService,
    private titleService: Title,
    private tableConfigService: TableConfigService,
    @Inject(FEATURE_TOGGLE) private featureToggleActive: FeatureToggleEvalFn,
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    Chart.register(ChartDataLabels);
    Chart.defaults.font = {
      family: "'Open Sans', Helvetica, Arial, sans-serif",
      size: 12,
      weight: 400,
    };
    if (this.init.hasBearerToken) {
      this.trackProgress(firstValueFrom(from(this.treeService.fetchTrees())));
      this.trackProgress(this.userConfigurationService.setup());
      this.trackProgress(firstValueFrom(this.tableConfigService.fetchTableConfigs()));
      this.trackProgress(firstValueFrom(this.consentService.fetchConsents()));

      this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          // do not show navigation menu for release notes
          if (event.url === '/newsfeed') {
            this.showNavigation = false;
          } else {
            this.showNavigation = true;
          }
        });
    }
    this.blockUiService.blockedUiEvent$.pipe(untilDestroyed(this)).subscribe((blockUi: boolean) => {
      this.uiBlocked = blockUi;
      if (blockUi === false) {
        // If switching fast from unblocked to blocked and vice versa it can happen that the blocked modal is not
        // removed from dom tree. This would lead to a frozen ui. So we'll remove it manually just to be sure.
        this.blockUiComponent?.destroyModal();
      }
    });
  }

  private trackProgress(item: Promise<any>) {
    this.total++;
    item
      .then(() => {
        this.serviceStarted();
      })
      .catch(() => {
        this.serviceStarted();
      });
  }

  private serviceStarted() {
    this.ready++;
    if (this.ready === this.total) {
      this.startupApp();
    }
  }

  private startupApp() {
    this.startApp = true;
    const subscription = this.navigationTrackingService.navigating$.subscribe({
      next: isRouting => {
        if (!isRouting) {
          this.busy = false;
          setTimeout(() => {
            // decouple from execution because if observable is false to begin with,
            // unsubscribing from a not fully initialized subscription raises an exception
            subscription.unsubscribe();
          }, 0);
        }
      },
    });
    this.releaseNoteService
      .getLatestReleaseNote(this.translateService.currentLang)
      .subscribe(releaseNote => {
        if (releaseNote !== undefined) {
          this.dialogService.open(ReleaseNoteDialogComponent, {
            data: {
              releaseNote,
            },
            showHeader: false,
            style: {
              'max-width': '950px',
              width: '95%',
              'max-height': '75%',
            },
            contentStyle: {
              display: 'flex',
              padding: '24px',
            },
          });
        }
      });

    this.setupDashboardNavItem();
    this.setupServiceNavItem();
    this.setupAssetNavItem();
    this.setupLiveConnectNavItem();
  }

  private setupLiveConnectNavItem() {
    if (this.licensingService.hasLiveConnect) {
      this.navItems.push({
        route: '/liveconnect/destinations',
        label: 'services.endpoints.label',
        pattern: /^\/liveconnect\/([^/]+\/)?destinations/,
        icon: 'custom_liveconnect',
      });
    }
  }

  private setupDashboardNavItem() {
    this.navItems.push({
      icon: 'custom_home',
      route: '/dashboard',
      label: 'services.dashboard.label',
      pattern: /^[/]dashboard[/][0-9a-f]{8}(?:-[0-9a-f]{4}){3}-[0-9a-f]{12}$/,
    });
  }

  private setupAssetNavItem() {
    this.navItems.push({
      label: 'services.assets.label',
      icon: 'custom_asset_management',
      children: [
        {
          label: 'services.assets.sub-menus.device-view',
          route: '/asset',
          pattern:
            /^[/]asset[/][0-9a-f]{8}(?:-[0-9a-f]{4}){3}-[0-9a-f]{12}[/](asset-management|liveconnect|service)([/].*)?$/,
        },
        {
          label: 'services.assets.sub-menus.map-view',
          route: '/asset/map',
          pattern: /^[/]asset[/][0-9a-f]{8}(?:-[0-9a-f]{4}){3}-[0-9a-f]{12}[/]map/,
        },
        {
          label: 'services.assets.sub-menus.search',
          route: '/search',
          pattern: /^[/]search/,
        },
      ],
    });
  }

  private setupServiceNavItem(): void {
    if (!this.licensingService.hasServiceModuleInSync) {
      return;
    }
    this.navItems.push({
      label: 'services.maintenance.label',
      icon: 'build',
      children: [
        {
          label: 'services.maintenance.sub-menus.requests',
          pattern:
            /^[/]maintenance365[/][0-9a-f]{8}(?:-[0-9a-f]{4}){3}-[0-9a-f]{12}[/]request(?:s|[/].+)$/,
          route: `/maintenance365/requests`,
        },
        {
          label: 'services.maintenance.sub-menus.appointments',
          pattern:
            /^[/]maintenance365[/][0-9a-f]{8}(?:-[0-9a-f]{4}){3}-[0-9a-f]{12}[/]appointments?(?:[/].+)?$/,
          route: `/maintenance365/appointments`,
        },
        {
          label: 'services.maintenance.sub-menus.workorders',
          pattern:
            /^[/]maintenance365[/][0-9a-f]{8}(?:-[0-9a-f]{4}){3}-[0-9a-f]{12}[/](workorder|service)(?:s|[/].+)$/,
          route: `/maintenance365/workorders`,
        },
        {
          label: 'services.maintenance.sub-menus.documents',
          pattern: /^[/]maintenance365[/][0-9a-f]{8}(?:-[0-9a-f]{4}){3}-[0-9a-f]{12}[/]documents$/,
          route: `/maintenance365/documents`,
        },
        {
          label: 'services.maintenance.sub-menus.contracts',
          pattern:
            /^[/]maintenance365[/][0-9a-f]{8}(?:-[0-9a-f]{4}){3}-[0-9a-f]{12}[/]contract(?:s|[/].+)$/,
          route: `/maintenance365/contracts`,
        },
      ],
    });
  }
}

import { inject, Injectable } from '@angular/core';
import { IccState } from '@liveconnect/shared/models';
import { DeviceMonitorService, IccService } from '@liveconnect/shared/services';
import { map, Observable, of, switchMap, timer } from 'rxjs';
import { ENVIRONMENT, GetAssetResponse } from '../models';
import { AssetService } from './asset.service';

export enum AssetConnectionState {
  VOID,
  OFFLINE,
  ONLINE,
}

export interface AssetConnnectionDetails {
  state: AssetConnectionState;
  stateDate?: Date;
}

@Injectable({ providedIn: 'root' })
export class AssetConnectionStateService {
  private assetService = inject(AssetService);
  private deviceMonitorService = inject(DeviceMonitorService);
  private iccService = inject(IccService);
  private timeoutDeviceOfflineSeconds = inject(ENVIRONMENT).timeoutDeviceOfflineSeconds;
  private cylceStateUpdateSeconds = 60;

  hasInventorySync(asset?: GetAssetResponse): boolean {
    return asset?.inventoryDetails?.synced !== undefined;
  }

  fetchConnectionState(asset?: GetAssetResponse): Observable<AssetConnnectionDetails> {
    if (asset?.inventoryDetails?.synced === undefined) {
      return of({ state: AssetConnectionState.VOID });
    }

    if (this.deviceMonitorService.canMonitorDevices()) {
      return this.fetchFromIccService(asset.uuid);
    }

    return this.fetchFromInventoryData(asset.uuid);
  }

  private fetchFromIccService(uuid: string): Observable<AssetConnnectionDetails> {
    return this.deviceMonitorService.getIccState(uuid).pipe(
      switchMap(iccState => {
        if (iccState === IccState.VOID) {
          return of({ state: AssetConnectionState.VOID });
        }
        return this.iccService.getCurrentConnection(uuid).pipe(
          map(connection => {
            const state = connection.connected
              ? AssetConnectionState.ONLINE
              : AssetConnectionState.OFFLINE;
            let stateDate: Date | undefined;

            if (connection.connected && connection.since) {
              stateDate = connection.since;
            } else if (!connection.connected && connection.lastSeen) {
              stateDate = connection.lastSeen;
            }

            return { state, stateDate };
          }),
        );
      }),
    );
  }

  private fetchFromInventoryData(uuid: string): Observable<AssetConnnectionDetails> {
    return (
      this.cylceStateUpdateSeconds > 0 ? timer(0, this.cylceStateUpdateSeconds * 1000) : of(0)
    ).pipe(
      switchMap(() =>
        this.assetService.fetchInventoryAssetDetails(uuid).pipe(
          map(details => {
            let state = AssetConnectionState.VOID;
            let stateDate: Date | undefined;

            if (details.lastConnectedDate !== undefined) {
              const now = new Date();
              if (now.getTime() >= details.lastConnectedDate.getTime()) {
                stateDate = details.lastConnectedDate;
                state =
                  (now.getTime() - stateDate.getTime()) / 1000 < this.timeoutDeviceOfflineSeconds
                    ? AssetConnectionState.ONLINE
                    : AssetConnectionState.OFFLINE;
              }
            }

            return { state, stateDate };
          }),
        ),
      ),
    );
  }
}

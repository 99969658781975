<p-dialog
  header="{{ 'asset.firmware.invalid.title' | translate }}"
  [draggable]="false"
  [resizable]="false"
  [(visible)]="displayInvalidFirmwareDialog"
  [modal]="true"
  [blockScroll]="true"
  [dismissableMask]="true"
  appendTo="body"
  [style]="{ 'max-width': 'inherit', 'line-height': 2 }"
>
  <div
    class="formatted-text"
    [innerHtml]="'asset.firmware.invalid.description' | translate | safeHtml"
  ></div>
</p-dialog>

@if (asset) {
  <div class="asset-header">
    <div class="asset-header-image">
      @if (updating || !profilePicture.ready) {
        <div class="image-frame image-loading">
          <p-progressSpinner></p-progressSpinner>
        </div>
      }
      @if (!updating && profilePicture.ready) {
        <div class="image-frame">
          @if (profilePicture.normal) {
            <div
              class="main-image-frame cursor-pointer"
              [ngStyle]="{
                'background-image': 'url(' + (profilePicture.normal! | completeUrl) + ')',
              }"
              (click)="openPicture($event)"
            >
              <div class="overlay">
                @if (!editAsset) {
                  <button
                    pButton
                    type="button"
                    class="ui-button-bare-neutral"
                    icon="pi pi-pencil"
                    (click)="navigateToImageAccordion($event)"
                  ></button>
                }
              </div>
              <!-- 404 detector -->
              <img
                style="display: none"
                [src]="sanitize(profilePicture.normal! | completeUrl)"
                (error)="fallbackToIcon()"
              />
            </div>
          } @else {
            <div class="main-image-frame">
              <img src="{{ defaultPictureUrl }}" />
              <div class="overlay">
                @if (!editAsset) {
                  <button
                    pButton
                    type="button"
                    class="ui-button-bare-neutral"
                    icon="pi pi-pencil"
                    (click)="navigateToImageAccordion($event)"
                  ></button>
                }
              </div>
            </div>
          }
        </div>
      }
    </div>
    @if (asset) {
      <div class="asset-header-details">
        <!-- Asset Title and Options -->
        <div class="asset-title-bar">
          @if (!editAsset) {
            <h2 class="asset-title">
              {{ title || defaultTitle }}
            </h2>
          } @else {
            <app-text-field
              class="asset-title editable"
              [placeholder]="defaultTitle"
              [(ngModel)]="title"
              [disabled]="updating"
            ></app-text-field>
          }

          @if (!editAsset) {
            <div class="options">
              <ng-container
                *ngTemplateOutlet="statusBar ? statusBar : emptyTemplate"
              ></ng-container>
              <div class="asset-map-button">
                <button
                  pButton
                  type="button"
                  class="ui-button-bare"
                  icon="pi pi-map"
                  (click)="navigateToMap()"
                ></button>
              </div>
              <app-asset-option-menu
                [asset]="asset"
                (editAsset)="enterEditMode()"
                id="option-expandable-menu"
              >
                <ng-container
                  *ngTemplateOutlet="optionMenu ? optionMenu : emptyTemplate"
                ></ng-container>
              </app-asset-option-menu>
            </div>
          }
        </div>
        <!-- Separator -->
        <hr class="title-separator" />
        <!-- Asset meta data -->
        <div class="asset-metadata-bar">
          <ng-template #assetTypeTemplate>
            <div>
              <span>{{ 'asset.type' | translate }}</span>
            </div>
            <div [ngClass]="{ 'edit-mode-enabled': editAsset && assetTypeChangeable }">
              @if (!editAsset || !assetTypeChangeable) {
                <div class="asset-type-container">
                  <span class="shrinkable-prop" id="type-description">{{
                    'assetTypes.' + currentTypeAsString | translate
                  }}</span>
                  <img id="type-image" [src]="'assets/images/' + currentTypeAsString + '.svg'" />
                </div>
              } @else {
                <div class="asset-type-container">
                  <p-dropdown
                    id="assetType"
                    name="assetType"
                    [(ngModel)]="typeSelected"
                    [options]="assetTypes"
                    scrollHeight="300px"
                    [overlayOptions]="{
                      showTransitionOptions: '0ms',
                      hideTransitionOptions: '0ms',
                    }"
                    (onChange)="typeChanged($event)"
                    [disabled]="updating"
                  >
                    <ng-template let-type pTemplate="selectedItem">
                      <img src="{{ type.icon }}" />
                      <span class="asset-type-label">{{ type.label | translate }}</span>
                    </ng-template>
                    <ng-template let-type pTemplate="item">
                      <img src="{{ type.icon }}" />
                      <span class="asset-type-label">{{ type.label | translate }}</span>
                    </ng-template>
                  </p-dropdown>
                </div>
              }
            </div>
          </ng-template>

          <ng-template #shipImoTemplate>
            <div>
              <span>{{ 'asset.imo-number' | translate }}</span>
            </div>
            <div [ngClass]="{ 'edit-mode-enabled': editAsset }">
              @if (!editAsset) {
                <span
                  #expandable
                  (click)="expandable.classList.toggle('expanded')"
                  class="shrinkable-prop"
                  >{{ shipImo || '-' }}</span
                >
              } @else {
                <app-text-field [(ngModel)]="shipImo" [disabled]="updating"></app-text-field>
              }
            </div>
          </ng-template>

          <ng-template #vendorTemplate>
            <div>
              <span>{{ 'asset.vendor' | translate }}</span>
            </div>
            @if (!editAsset || vendorUpdatable === false) {
              <div>
                <span
                  class="shrinkable-prop"
                  #expandable
                  (click)="expandable.classList.toggle('expanded')"
                  >{{ vendor || '-' }}</span
                >
              </div>
            } @else {
              <div class="edit-mode-enabled">
                <app-text-field [(ngModel)]="vendor" [disabled]="updating"></app-text-field>
              </div>
            }
          </ng-template>

          <ng-template #serialNumberTemplate>
            <div>
              <span>{{ 'asset.serial-number' | translate }}</span>
            </div>
            @if (!editAsset || serialNumberUpdatable === false) {
              <div>
                <div class="serial-number-container">
                  <span
                    class="shrinkable-prop"
                    #expandable
                    (click)="expandable.classList.toggle('expanded')"
                    >{{ serialNumber || '-' }}</span
                  >
                </div>
              </div>
            } @else {
              <div class="edit-mode-enabled">
                <app-text-field [(ngModel)]="serialNumber" [disabled]="updating"></app-text-field>
              </div>
            }
          </ng-template>

          <ng-template #productNameTemplate>
            <div>
              <span>{{ 'asset.product-name' | translate }}</span>
            </div>
            <div [ngClass]="{ 'edit-mode-enabled': editAsset }">
              @if (!editAsset || productNameUpdatable === false) {
                <div
                  class="shrinkable-prop"
                  #expandable
                  (click)="expandable.classList.toggle('expanded')"
                >
                  @if (canShowShopLink()) {
                    <span class="link-sick-shop">
                      <a
                        title="{{ 'assetDetails.linkSickShop' | translate }}"
                        href="{{ shopPath | completeUrl }}"
                        target="_blank"
                        rel="noopener"
                        class="shrinkable-prop"
                        #expandable
                        (click)="expandable.classList.toggle('expanded')"
                        >{{ productName || '-' }}</a
                      >
                    </span>
                  } @else {
                    <span class="shrinkable-prop">{{ productName || '-' }}</span>
                  }
                </div>
              } @else {
                <app-text-field [(ngModel)]="productName" [disabled]="updating"></app-text-field>
              }
            </div>
          </ng-template>

          <ng-template #partNumberTemplate>
            <div>
              <span>{{ 'asset.part-number' | translate }}</span>
            </div>
            @if (!editAsset || partNumberUpdatable === false) {
              <div>
                <span
                  class="shrinkable-prop"
                  #expandable
                  (click)="expandable.classList.toggle('expanded')"
                  >{{ partNumber || '-' }}</span
                >
              </div>
            } @else {
              <div class="edit-mode-enabled">
                <app-text-field [(ngModel)]="partNumber" [disabled]="updating"></app-text-field>
              </div>
            }
          </ng-template>

          <ng-template #firmwareVersionTemplate>
            <div>
              <span>{{ 'asset.firmware.version' | translate }}</span>
            </div>
            @if (!editAsset) {
              <div>
                <div class="firmware-field">
                  <span
                    class="shrinkable-prop"
                    #expandable
                    (click)="expandable.classList.toggle('expanded')"
                    >{{ firmwareVersion || '-' }}
                  </span>
                  <app-firmware-state-icon
                    [firmwareState]="firmwareState"
                  ></app-firmware-state-icon>
                </div>
              </div>
            } @else {
              <div class="edit-mode-enabled">
                <app-text-field
                  [(ngModel)]="firmwareVersion"
                  [disabled]="updating"
                  (ngModelChange)="validateVersion()"
                  (blurred)="onVersionLeaveFocus()"
                  [handleErrorIconClick]="showVersionHelp"
                  [error]="showVersionErrorIcon"
                ></app-text-field>
              </div>
            }
          </ng-template>

          <ng-template #deploymentDateTemplate>
            <div>
              <span>{{ 'asset.deployment-date.' + deploymentDateLanguageKey | translate }}</span>
            </div>
            @if (!editAsset) {
              <div>
                <div class="deployment-date-field">
                  <span
                    class="shrinkable-prop"
                    #expandable
                    (click)="expandable.classList.toggle('expanded')"
                    >{{
                      deploymentDateTimestamp
                        ? (deploymentDateTimestamp * 1000 | localizedDate: 'mediumDate')
                        : '-'
                    }}
                  </span>
                </div>
              </div>
            } @else {
              <div class="edit-mode-enabled">
                <app-date-field [disabled]="updating" [(ngModel)]="deploymentDate">
                </app-date-field>
              </div>
            }
          </ng-template>

          <ng-template #updatedAtTemplate>
            @if (asset.inventoryDetails && asset.inventoryDetails.updatedTime) {
              <div>
                <span>{{ 'asset.updated-at' | translate }}</span>
              </div>
              <div>
                <span
                  class="shrinkable-prop"
                  #expandable
                  (click)="expandable.classList.toggle('expanded')"
                  >{{ asset.inventoryDetails.updatedTime * 1000 | localizedDate: 'medium' }}</span
                >
              </div>
            }
          </ng-template>

          <ng-template #connectionStateTemplate>
            <div>
              <span>{{ 'asset.connection-state' | translate }}</span>
            </div>
            <div>
              <app-asset-connection-state
                class="shrinkable-prop"
                [asset]="asset"
              ></app-asset-connection-state>
            </div>
          </ng-template>

          <ng-container>
            <!-- Metadata table - Single column style -->
            <div class="asset-prop-table">
              @if (isProduct()) {
                <!-- Vendor data -->
                <ng-container *ngTemplateOutlet="vendorTemplate"></ng-container>
              }
              <!-- Asset Type -->
              <ng-container *ngTemplateOutlet="assetTypeTemplate"></ng-container>
              <!-- Regular fields in common for all assets -->
              @if (isProduct()) {
                <!-- Product name data -->
                <ng-container *ngTemplateOutlet="productNameTemplate"></ng-container>
                <!-- part number data -->
                <ng-container *ngTemplateOutlet="partNumberTemplate"></ng-container>
                <!-- Serial no -->
                @if (hasSerialNumber()) {
                  <ng-container *ngTemplateOutlet="serialNumberTemplate"></ng-container>
                }
                <!-- Firmware version -->
                <ng-container *ngTemplateOutlet="firmwareVersionTemplate"></ng-container>
              }
              @if (isTypeShip()) {
                <!-- IMO -->
                <ng-container *ngTemplateOutlet="shipImoTemplate"></ng-container>
              }
              @if (showDeploymentDateField) {
                <!-- deployment Date -->
                <ng-container *ngTemplateOutlet="deploymentDateTemplate"></ng-container>
              }
              @if (asset.inventoryDetails && asset.inventoryDetails.updatedTime) {
                <!-- Updated at timestamp -->
                <ng-container *ngTemplateOutlet="updatedAtTemplate"></ng-container>
              }
              @if (hasInventorySync()) {
                <!-- Connection state -->
                <ng-container *ngTemplateOutlet="connectionStateTemplate"></ng-container>
              }
            </div>
          </ng-container>
          <!-- Phased out warn -->
          @if (!editAsset && phaseOutWarningMsg && featureToggleActive('PHASED_OUT_STATE')) {
            <app-callout severity="warn">
              <span>{{ phaseOutWarningMsg | translate }}</span>
              @if (successor) {
                <span>
                  {{ 'assetDetails.successor' | translate }}
                  <a
                    href="{{ successor.Target.PartNumber | completeUrl }}"
                    target="_blank"
                    rel="noopener"
                    >{{ successor.Target.Name }}</a
                  ></span
                >
              }
            </app-callout>
          }

          <!-- Edit mode options -->
          @if (editAsset) {
            <hr class="button-separator" />
            <app-save-cancel-options
              [cancelDisabled]="updating"
              [saveDisabled]="updating"
              (cancelClick)="reset()"
              (saveClick)="saveChangesToAsset()"
            ></app-save-cancel-options>
          }
        </div>
      </div>
    }
  </div>
}
<ng-template #emptyTemplate></ng-template>

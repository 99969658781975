export interface OperatingData {
  name: string;
  value: string | number;
  updatedAt: Date;
}

export interface OperatingDataResponse {
  name: string;
  value: string | number;
  updatedAt: string;
}

export interface AssetInventoryDetails {
  lastConnectedDate?: Date;
}

export interface AssetInventoryDetailsResponse {
  lastConnectedDate?: number;
}
